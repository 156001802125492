



































import ProjectValuationMarket from "@/components/project-valuation-market/ProjectValuationMarket.vue";
import { Component } from "vue-property-decorator";
import ResizableSidebarLayout from "@/layouts/ResizableSidebarLayout.vue";
import BuyerAnalyticsForm from "@/components/buyer-analytics/BuyerAnalyticsForm.vue";
import BuyerAnalyticsBuyersDataTable from "@/components/buyer-analytics/BuyerAnalyticsBuyersDataTable.vue";
import ProjectValuationBuyersDataTable from "@/components/project-valuation-buyers/ProjectValuationBuyersDataTable.vue";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";
import Contact from "@/entity/Contact";
import { TableQuery } from "@/components/data-table/types";
import BuyerAnalyticsService from "@/services/BuyerAnalyticsService";
import { BuyerValuationFilterInput } from "@/entity/Analytics";
import { Mixins } from "vue-property-decorator";
import DialogMixin from "@/mixins/DialogMixin";
import * as XLSX from "xlsx";
import ExportProjectBuyers from "@/components/project-buyers/ExportProjectBuyers.vue";
import ProjectValuationBuyerTypeTiles from "@/components/project-valuation-buyers/ProjectValuationBuyerTypeTiles.vue";

@Component({
  components: {
    ProjectValuationBuyerTypeTiles,
    ExportProjectBuyers,
    ProjectValuationBuyersDataTable,
    ResizableSidebarLayout,
    ProjectValuationMarket,
    BuyerAnalyticsForm,
    BuyerAnalyticsBuyersDataTable,
  },
})
export default class BuyerAnalyticsView extends Mixins(DialogMixin) {
  @State("buyerTypes", { namespace: "selectOptions" })
  buyerTypes!: ReferenceItem[];

  selectedContacts: Contact[] = [];
  contacts: Contact[] = [];
  totalItems = 0;
  isSubmitting = false;
  tableQuery!: TableQuery;
  filterInput: BuyerValuationFilterInput = {};

  handleSubmit(filterInput: BuyerValuationFilterInput) {
    this.filterInput = filterInput;
    this.filterBuyers();
  }

  async filterBuyers(): Promise<void> {
    try {
      this.isSubmitting = true;
      const { content, totalItems } = await BuyerAnalyticsService.search({
        ...this.filterInput,
        page: this.tableQuery.page,
        itemsPerPage: this.tableQuery.itemsPerPage,
      });
      this.contacts = content;
      this.totalItems = totalItems;
      this.selectedContacts = [];
    } finally {
      this.isSubmitting = false;
    }
  }

  onOptionsChange(tableQuery: TableQuery) {
    this.tableQuery = tableQuery;
    this.filterBuyers();
  }

  openExportDialog(): void {
    this.openDialog("export");
  }

  async handleExport(filename: string): Promise<any> {
    const response = await BuyerAnalyticsService.search({
      ...this.filterInput,
      excludedContacts: this.selectedContacts.map(({ id }) => id as number),
      page: 1,
      itemsPerPage: 9999999,
    });
    const finalData = response.content.map((contact) => ({
      [this.$tc("firstName")]: contact?.lastName,
      [this.$tc("name")]: contact?.firstName,
      [this.$tc("email")]: contact?.email,
      [this.$tc("companyName")]: contact.company?.name,
      [this.$tc("phoneMobile")]: contact?.phoneMobile,
      [this.$tc("buyerType")]: contact?.buyerType?.name,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(finalData);

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      this.$tc("buyerAnalysis", 1)
    );

    XLSX.writeFile(workbook, `${filename}.xlsx`, { compression: true });
    this.closeDialog();
  }
}
