import Axios from "axios";
import { BuyerValuationFilterInput } from "@/entity/Analytics";
import Contact from "@/entity/Contact";
import { ListResponseEntity } from "@/services/types";

export default class BuyerAnalyticsService {
  public static mapQuery(query: BuyerValuationFilterInput) {
    const result: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(query)) {
      if (
        value === null ||
        value === undefined ||
        (Array.isArray(value) && value.length === 0)
      ) {
        continue;
      }
      result[key] = value.toString();
    }
    return result;
  }

  public static async search(
    query: BuyerValuationFilterInput
  ): Promise<ListResponseEntity<Contact>> {
    const response = await Axios.get("/api/v2/buyer-analytics", {
      params: this.mapQuery(query),
    });
    return response.data;
  }
}
