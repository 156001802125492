

















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BuyerAnalyticsDataTableToolbar extends Vue {
  @Prop({ type: Number, required: true }) selected!: number;
  @Prop({ required: true }) colNum!: number;
  @Prop({ default: false }) isMobile!: boolean;
}
